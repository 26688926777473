//流转审批
import service from '../request/index'
const baseUrl = window.globalUrl.HOME_API;

// 新版本-获取土地类型
export const selectNoApproval = (data) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectNoApproval`,
    method: 'get',
    params: data
  })
}
